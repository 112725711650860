export const HeadCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: '#',
        sort: true,
        type: 'text',
    },
    {
        id: 'coupon_code',
        numeric: false,
        disablePadding: false,
        label: 'Code',
        sort: false,
        type: 'text',
    },
    {
        id: 'coupon_code__type_name',
        numeric: false,
        disablePadding: false,
        label: 'Type',
        sort: true,
        type: 'text',
    },
    {
        id: 'coupon_activation_time',
        numeric: true,
        disablePadding: false,
        label: 'Activation',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'coupon_expiration_time',
        numeric: true,
        disablePadding: false,
        label: 'Expiration',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'active',
        numeric: true,
        disablePadding: false,
        label: 'Active',
        sort: true,
        type: 'boolean',
    },
];
export const dataFields = [
    'id',
    'coupon_code',
    'coupon_code__type_name',
    'coupon_activation_time',
    'coupon_expiration_time',
    'active'
];
