import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { Button } from '../Button';
import Delete from '@/components/Icons/Delete';

type DeleteCommentProps = {
    btnTitle: string;
    onClose: () => void;
    onSuccess: () => void;
};

export const Confirm = ({ btnTitle, onClose, onSuccess }: DeleteCommentProps) => {
    return (
        <Dialog
            open={true}
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
        >
            <DialogTitle>Confirm action</DialogTitle>
            <DialogContent dividers>Are you sure you want to {btnTitle}?</DialogContent>
            <DialogActions>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '10px',
                    }}
                >
                    <Button
                        startIcon={<Delete color={'white'} />}
                        type="button"
                        onClick={onSuccess}
                        style={{ width: 'fit-content' }}
                    >
                        {btnTitle}
                    </Button>
                    <Button
                        type="button"
                        onClick={onClose}
                        variant="secondary"
                        style={{ width: 'fit-content' }}
                    >
                        Cancel
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};
