export const HeadCells = [
    // {
    //     id: 'id',
    //     numeric: true,
    //     disablePadding: true,
    //     label: '#',
    //     sort: true,
    //     type: 'text',
    // },
    {
        id: 'location_name',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        sort: true,
        type: 'text',
    },
    {
        id: 'category_name',
        numeric: false,
        disablePadding: false,
        label: 'Category Name',
        sort: false,
        type: 'text',
    },
    {
        id: 'subcategory',
        numeric: false,
        disablePadding: false,
        label: 'Order Type',
        sort: false,
        type: 'text',
    },

    {
        id: 'multiplier',
        numeric: true,
        disablePadding: false,
        label: 'Multiplier',
        sort: true,
        type: 'text',
    },
    {
        id: 'base',
        numeric: true,
        disablePadding: false,
        label: 'Base',
        sort: true,
        type: 'text',
    },
    // {
    //     id: 'date_created',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Created',
    //     sort: true,
    //     type: 'dateTime',
    // },
    {
        id: 'active',
        numeric: true,
        disablePadding: false,
        label: 'Active',
        sort: true,
        type: 'boolean',
    },
];
export const dataFields = [
    'location_name',
    'category_name',
    'subcategory',
    'multiplier',
    'base',
    'active',
];
