export const HeadCells_menu_group = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: '#',
        sort: true,
        type: 'text',
    },
    {
        id: 'menu_group_name',
        numeric: false,
        disablePadding: false,
        label: 'Menu Name',
        sort: false,
        type: 'text',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        sort: false,
        type: 'text',
    },
    {
        id: 'date_created',
        numeric: true,
        disablePadding: false,
        label: 'Created',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'date_updated',
        numeric: true,
        disablePadding: false,
        label: 'Last Update',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'active',
        numeric: true,
        disablePadding: false,
        label: 'Active',
        sort: true,
        type: 'boolean',
    },
];

export const HeadCells_menu_live = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: '#',
        sort: true,
        type: 'text',
    },
    {
        id: 'menu_group_name',
        numeric: false,
        disablePadding: false,
        label: 'Menu Name',
        sort: false,
        type: 'text',
    },
    {
        id: 'location_name',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        sort: true,
        type: 'text',
    },
    {
        id: 'activation_time',
        numeric: true,
        disablePadding: false,
        label: 'Activation Date',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'expiration_time',
        numeric: true,
        disablePadding: false,
        label: 'Expiration Date',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'active',
        numeric: true,
        disablePadding: false,
        label: 'Active',
        sort: true,
        type: 'boolean',
    },
];

export const dataFields_menu_group = [
    'id',
    'menu_group_name',
    'status',
    'date_created',
    'date_updated',
    'active',
];

export const dataFields_menu_live = [
    'id',
    'menu_group_name',
    'location_name',
    'activation_time',
    'expiration_time',
    'active',
];
