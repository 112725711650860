export const HeadCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Request Id',
        sort: true,
        type: 'text',
    },
    {
        id: 'contact_full_name',
        numeric: false,
        disablePadding: false,
        label: 'Customer',
        sort: false,
        type: 'text',
    },
    {
        id: 'contact_email',
        numeric: false,
        disablePadding: false,
        label: 'Contact Email',
        sort: false,
        type: 'number',
    },
    {
        id: 'contact_phone',
        numeric: false,
        disablePadding: false,
        label: 'Phone',
        sort: false,
        type: 'number',
    },
    {
        id: 'contact_comment',
        numeric: true,
        disablePadding: false,
        label: 'Comment',
        sort: true,
        type: 'text',
    },
];
export const HeadCellsCareers = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Request Id',
        sort: true,
        type: 'text',
    },
    {
        id: 'applicant_first_name',
        numeric: false,
        disablePadding: false,
        label: 'Applicant First Name',
        sort: false,
        type: 'text',
    },
    {
        id: 'applicant_last_name',
        numeric: false,
        disablePadding: false,
        label: 'Applicant Last Name',
        sort: false,
        type: 'text',
    },

    {
        id: 'applicant_email',
        numeric: false,
        disablePadding: false,
        label: 'Applicant Email',
        sort: false,
        type: 'text',
    },
    {
        id: 'applicant_phone',
        numeric: false,
        disablePadding: false,
        label: 'Phone',
        sort: false,
        type: 'number',
    },
    {
        id: 'applicant_employment_desired',
        numeric: true,
        disablePadding: false,
        label: 'Job Type',
        sort: true,
        type: 'text',
    },
    {
        id: 'applicant_position',
        numeric: true,
        disablePadding: false,
        label: 'Applicant Position',
        sort: true,
        type: 'text',
    },
];
export const dataFields = [
    'id',
    'contact_full_name',
    'contact_email',
    'contact_phone',
    'contact_comment',
];
export const dataFieldsCareers = [
    'id',
    'applicant_first_name',
    'applicant_last_name',
    'applicant_email',
    'applicant_phone',
    'applicant_employment_desired',
    'applicant_position',
];
