import { AddCircleOutline } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CSVLink } from 'react-csv';
import {
    ChartComponent,
    SeriesCollectionDirective,
    SeriesDirective,
    Inject,
    LineSeries,
    Legend,
    DateTime,
    Tooltip,
    Highlight,
} from '@syncfusion/ej2-react-charts';
import { Browser, registerLicense } from '@syncfusion/ej2-base';
import { getTaxReport } from '../api/CRUD/getTaxReport';
import { getSalesReport } from '../api/CRUD/getSalesReport';
import { getProductReport } from '../api/CRUD/getProductReport';
import { getDiscountReport } from '../api/CRUD/getDiscountReport';
import { getDeliveryReport } from '../api/CRUD/getDeliveryReport';
import ViewReport from '../components/view';
import {
    dataFieldsDelivery,
    dataFieldsDiscount,
    dataFieldsEmployee,
    dataFieldsGiftCard,
    dataFieldsProduct,
    dataFieldsRedemption,
    dataFieldsSale,
    dataFieldsSaleByMode,
    dataFieldsSaleByType,
    dataFieldsTax,
    HeadCellsDelivery,
    HeadCellsDiscount,
    HeadCellsEmployee,
    HeadCellsGiftCard,
    HeadCellsProduct,
    HeadCellsRedemption,
    HeadCellsSale,
    HeadCellsSaleByMode,
    HeadCellsSaleByType,
    HeadCellsTax,
} from '../constant';
import AddTarget from '../components/addTarget';
import SendTarget from '../components/sendTarget';
import { getBasicReport } from '../api/CRUD/getBasicReport';
import { Report } from '../type';
import { useNotificationStore } from '@/stores/notifications';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
// import TableWithSort from '@/components/Elements/Table/TableWithSort';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { Confirm } from '@/components/Elements/Dialog/Confirm';
import { Button, Dialog, Spinner } from '@/components/Elements';

import 'react-circular-progressbar/dist/styles.css';
//import { HeadCells } from '@/features/users/constant';
import '../styles/styles.css';
import { axios } from '@/lib/axios';
import moment from 'moment';

registerLicense(
    'Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCdkx3R3xbf1xzZFJMYF9bRnBPMyBoS35RdUVkW3lfcXZdR2FYUEF/'
);

export const ReportPage = () => {
    const { close, open, isOpen } = useDisclosure();
    const { addNotification } = useNotificationStore();

    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [report, setReport] = useState<Report[]>([]);
    const [livereport, setLiveReport] = useState<Report[]>([]);
    const [taxReport, setTaxReport] = useState<Report[]>([]);
    const [salesReport, setSalesReport] = useState<Report[]>([]);
    const [salesReportByMode, setSalesReportByMode] = useState<Report[]>([]);
    const [salesReportByType, setSalesReportByType] = useState<Report[]>([]);
    const [giftCardReport, setGiftCardReport] = useState<Report[]>([]);
    const [productReport, setProductReport] = useState<Report[]>([]);
    const [discountReport, setDiscountReport] = useState<Report[]>([]);
    const [deliveryReport, setDeliveryReport] = useState<Report[]>([]);
    const [basicReport, setBasicReport] = useState<Report[]>([]);
    const [taxTotal, setTaxTotal] = useState();
    const [salesTotal, setSalesTotal] = useState();
    const [productsTotal, setProductsTotal] = useState();
    const [discountTotal, setDiscountTotal] = useState(0);
    const [totalUsedDiscount, setTotalUsedDiscount] = useState(0);

    const [redemptionReport, setRedemptionReport] = useState<Report[]>([]);
    const [employeeMealReport, setEmployeeMealReport] = useState<Report[]>([]);

    const [viewReport, setviewReport] = useState<Report | null>(null);
    const [addTarget, setaddTarget] = useState<Report | null>(null);
    const [sendTarget, setSendTarget] = useState<Report | null>(null);
    const [locationFilter, setLocationFilter] = useState();
    const [confirmMsg, setConfirmMsg] = useState<User | null>(null);
    const [value, setValue] = useState(0);

    const [salesReports, setSalesReports] = useState<Report[]>([]);
    const [charData, setCharData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [filters, setFilters] = useState('Today');
    const [chartLoading, setChartLoading] = useState(false);
    const [chartTitle, setChartTitle] = useState('Total Sales Report By Location');
    const [chartYAxisTitle, setChartYAxisTitle] = useState('Total Sales in $');
    const [orderTotal, setOrderTotal] = useState(0);
    const [giftCardTotal, setGiftCardTotal] = useState(0);
    const [giftCardTotalAmount, setGiftCardTotalAmount] = useState(0);
    const [deliveryTotal, setDeliveryTotal] = useState();
    const [deliveryTotalAmount, setDeliveryTotalAmount] = useState(0);
    const [redemptionTotal, setRedemptionTotal] = useState();
    const [redemptionTotalAmount, setRedemptionTotalAmount] = useState(0);
    const [employeeTotal, setEmployeeTotal] = useState();
    const [employeeTotalAmount, setEmployeeTotalAmount] = useState(0);
    const [orderIds, setOrderIds] = useState([]);
    const [csvDatas, setCSVDatas] = useState([]);
    const [finalcsvDatas, setFinalCSVDatas] = useState([]);
    const ProductCsv: any = ['Product Name', 'Total Items', 'Total Sales'];
    const SalesCsv: any = [
        'Order Id',
        'Promise Time',
        'Creation Date',
        'Location',
        'Order Type',
        'Order Mode',
        'Subtotal',
        'Tax',
        'Delivery Fee',
        'Tip',
        'Promo',
        'Employee Discount',
        'Adjustment',
        'Refund',
        'Gift Card Redemption',
        'Total',
        'Net Sales',
    ];
    useEffect(() => {
        if (csvDatas?.length > 0) {
            setFinalCSVDatas([value == 8 ? ProductCsv : SalesCsv, ...csvDatas]);
        }
    }, [csvDatas]);

    const getTotalSales = async (params?: any) => {
        axios
            .get(`v1/admin/reports/get-sales-report-by-location?filter=${params ?? filters}`)
            .then(async (res) => {
                setSalesReports(((res?.data.total_sales * 1) / 1).toFixed(2));

                // res.data.report.forEach((location: any) => {
                //     for (const timeSlot in location.report) {
                //         totalOrders += location.data[timeSlot][0].order_count;
                //     }
                // });

                setChartYAxisTitle('Total Sales in $');
                const seriesName = res.data?.report?.map((item: any) => {
                    return item.name;
                });
                const orderId = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        return ele.order_ids;
                    });
                });
                const flattened = orderId.reduce((acc, current) => acc.concat(...current), []);
                // const flattened = [].concat(...orderId);
                setOrderIds(flattened);

                const resSalesMod = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        const inputDate = ele.time;
                        const report: any = {};
                        report.location = item.name;
                        report.date = inputDate;
                        report.totalSales = `$ ${ele.total_sales}`;
                        report.totalOrders = ele.order_count;

                        return report;
                    });
                });
                const flattenedArray = [].concat(...resSalesMod);

                const totalsByLocation = flattenedArray.reduce((acc: any, current: any) => {
                    const location = current.location.trim();
                    if (!acc[location]) {
                        acc[location] = { totalSales: 0, totalOrders: 0 };
                    }
                    acc[location].totalSales += parseFloat(current.totalSales.replace('$', ''));
                    acc[location].totalOrders += current.totalOrders;
                    return acc;
                }, {});
                let totalOrders: any = 0;
                Object.entries(totalsByLocation).map(([location, totals]) => {
                    totalOrders += totals.totalOrders;
                });
                // Create a new array with total sales and total orders for different locations
                const resultArray = Object.entries(totalsByLocation).map(([location, totals]) => ({
                    location: location,
                    totalSales: `$ ${totals.totalSales.toFixed(2)}`,
                    totalOrders: totals.totalOrders,
                    average: `$ ${
                        totals.totalOrders != 0
                            ? (totals.totalSales / totals.totalOrders).toFixed(2)
                            : totals.totalSales.toFixed(2)
                    }`,
                }));
                setSalesReport(resultArray);
                const seriesData = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        const date = ele.time;
                        const order = ele.total_sales.toFixed(2);
                        const totalOrders = ele.order_count;
                        return { x: date, y: order };
                    });
                });

                setOrderTotal(totalOrders);
                setCharData(seriesData);
                setData1(seriesName);
                setData2(seriesName);
                setChartLoading(false);
                if (flattened?.length > 0) {
                    const data = await axios
                        .post(`v1/admin/reports/get-report-orders`, {
                            orders: flattened,
                        })
                        .then((data) => {
                            return data;
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    const CsvData = data?.data?.map((ele: any) => {
                        return [
                            ele.order_id,
                            ele.promise_time,
                            ele.creation_date,
                            ele.location_name,
                            ele.order_type,
                            ele.order_mode,
                            ele.sub_total,
                            ele.tax,
                            ele.deliver_fee,
                            ele.tip,
                            ele.discounts,
                            ele.employee_discount,
                            ele.adjustment,
                            ele.refund,
                            ele.gc_redemption,
                            ele.total,
                            ele.net_sales,
                        ];
                    });
                    setCSVDatas(CsvData);
                }
            });
    };

    const getTotalSalesByMode = async (params?: any) => {
        axios
            .get(`v1/admin/reports/get-sales-report-by-mode?filter=${params ?? filters}`)
            .then(async (res) => {
                setSalesReports(((res?.data.total_sales * 1) / 1).toFixed(2));

                const seriesName = res.data?.report?.map((item: any) => {
                    return item.name;
                });

                setChartYAxisTitle('Total Sales in $');
                const resSalesMod = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        // dats[ele];
                        const inputDate = ele.time;
                        const report: any = {};
                        report.mode = item.name;
                        // report.location = dats[ele][0].date;
                        report.date = inputDate;
                        report.totalSales = `$ ${ele.total_sales}`;
                        report.totalOrders = ele.order_count;

                        return report;
                    });
                });

                const flattenedArray = [].concat(...resSalesMod);
                const totalsByLocation = flattenedArray.reduce((acc: any, current: any) => {
                    const mode = current.mode.trim();
                    if (!acc[mode]) {
                        acc[mode] = { totalSales: 0, totalOrders: 0 };
                    }
                    acc[mode].totalSales += parseFloat(current.totalSales.replace('$', ''));
                    acc[mode].totalOrders += current.totalOrders;
                    return acc;
                }, {});

                let totalOrders: any = 0;
                Object.entries(totalsByLocation).map(([mode, totals]) => {
                    totalOrders += totals.totalOrders;
                });

                // Create a new array with total sales and total orders for different locations
                const resultArray = Object.entries(totalsByLocation).map(([mode, totals]) => ({
                    mode: mode,
                    totalSales: `$ ${totals.totalSales.toFixed(2)}`,
                    totalOrders: totals.totalOrders,
                    average: `$ ${
                        totals.totalOrders != 0
                            ? (totals.totalSales / totals.totalOrders).toFixed(2)
                            : totals.totalSales.toFixed(2)
                    }`,
                }));
                setSalesReportByMode(resultArray);
                const seriesData = res.data?.report?.map((item: any) => {
                    // const dats = item.data;
                    // const dhgf = Object.keys(dats);
                    return item.report.map((ele: any) => {
                        const date = ele.time;
                        const order = ele.total_sales.toFixed(2);
                        return { x: date, y: order };
                    });
                });

                setOrderTotal(totalOrders);
                setCharData(seriesData);
                setData1(seriesName);
                setChartLoading(false);
                const orderId = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        return ele.order_ids;
                    });
                });
                const flattened = orderId.reduce((acc, current) => acc.concat(...current), []);
                setOrderIds(flattened);
                if (flattened?.length > 0) {
                    const data = await axios
                        .post(`v1/admin/reports/get-report-orders`, {
                            orders: flattened,
                        })
                        .then((data) => {
                            return data;
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    const CsvData = data?.data?.map((ele: any) => {
                        return [
                            ele.order_id,
                            ele.promise_time,
                            ele.creation_date,
                            ele.location_name,
                            ele.order_type,
                            ele.order_mode,
                            ele.sub_total,
                            ele.tax,
                            ele.deliver_fee,
                            ele.tip,
                            ele.discounts,
                            ele.employee_discount,
                            ele.adjustment,
                            ele.refund,
                            ele.gc_redemption,
                            ele.total,
                            ele.net_sales,
                        ];
                    });
                    setCSVDatas(CsvData);
                }
            });
    };

    const getTotalSalesByType = async (params?: any) => {
        axios
            .get(`v1/admin/reports/get-sales-report-by-type?filter=${params ?? filters}`)
            .then(async (res) => {
                setChartYAxisTitle('Total Sales in $');
                setSalesReports(((res?.data.total_sales * 1) / 1).toFixed(2));

                const seriesName = res.data?.report?.map((item: any) => {
                    return item.name;
                });
                const resSalesMod = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        // dats[ele];
                        const inputDate = ele.time;
                        const report: any = {};
                        report.type = item.name;
                        // report.location = dats[ele][0].date;
                        report.date = inputDate;
                        report.totalSales = `$ ${ele.total_sales}`;
                        report.totalOrders = ele.order_count;

                        return report;
                    });
                });
                const flattenedArray = [].concat(...resSalesMod);
                const totalsByLocation = flattenedArray.reduce((acc: any, current: any) => {
                    const type = current.type.trim();
                    if (!acc[type]) {
                        acc[type] = { totalSales: 0, totalOrders: 0 };
                    }
                    acc[type].totalSales += parseFloat(current.totalSales.replace('$', ''));
                    acc[type].totalOrders += current.totalOrders;
                    return acc;
                }, {});
                let totalOrders: any = 0;
                Object.entries(totalsByLocation).map(([type, totals]) => {
                    totalOrders += totals.totalOrders;
                });
                // Create a new array with total sales and total orders for different locations
                const resultArray = Object.entries(totalsByLocation).map(([type, totals]) => ({
                    type: type,
                    totalSales: `$ ${totals.totalSales.toFixed(2)}`,
                    totalOrders: totals.totalOrders,
                    average: `$ ${
                        totals.totalOrders != 0
                            ? (totals.totalSales / totals.totalOrders).toFixed(2)
                            : totals.totalSales.toFixed(2)
                    }`,
                }));
                setSalesReportByType(resultArray);
                const seriesData = res.data?.report?.map((item: any) => {
                    // const dats = item.data;
                    // const dhgf = Object.keys(dats);
                    return item.report.map((ele: any) => {
                        const order = ele.total_sales.toFixed(2);
                        return { x: ele.time, y: order };
                    });
                });
                setOrderTotal(totalOrders);
                setCharData(seriesData);
                setData1(seriesName);
                setChartLoading(false);
                const orderId = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        return ele.order_ids;
                    });
                });
                const flattened = orderId.reduce((acc, current) => acc.concat(...current), []);
                setOrderIds(flattened);
                if (flattened?.length > 0) {
                    const data = await axios
                        .post(`v1/admin/reports/get-report-orders`, {
                            orders: flattened,
                        })
                        .then((data) => {
                            return data;
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    const CsvData = data?.data?.map((ele: any) => {
                        return [
                            ele.order_id,
                            ele.promise_time,
                            ele.creation_date,
                            ele.location_name,
                            ele.order_type,
                            ele.order_mode,
                            ele.sub_total,
                            ele.tax,
                            ele.deliver_fee,
                            ele.tip,
                            ele.discounts,
                            ele.employee_discount,
                            ele.adjustment,
                            ele.refund,
                            ele.gc_redemption,
                            ele.total,
                            ele.net_sales,
                        ];
                    });
                    setCSVDatas(CsvData);
                }
            });
    };

    const getGiftCardReport = async (params?: any) => {
        axios
            .get(`v1/admin/reports/get-gift-card-report?filter=${params ?? filters}`)
            .then(async (res) => {
                const resGiftCardMod = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        const inputDate = ele.time;
                        const report: any = {};
                        report.location = item.name;
                        report.date = inputDate;
                        report.totalSales = `$ ${ele.total_gc_redemption}`;
                        report.totalOrders = ele.order_count;

                        return report;
                    });
                });
                const flattenedArray = [].concat(...resGiftCardMod);
                const totalsByLocation = flattenedArray.reduce((acc: any, current: any) => {
                    const location = current.location.trim();
                    if (!acc[location]) {
                        acc[location] = { totalSales: 0, totalOrders: 0 };
                    }
                    acc[location].totalSales += parseFloat(current.totalSales.replace('$', ''));
                    acc[location].totalOrders += current.totalOrders;
                    return acc;
                }, {});
                let totalOrders: any = 0;
                Object.entries(totalsByLocation).map(([location, totals]) => {
                    totalOrders += totals.totalOrders;
                }); // Create a new array with total sales and total orders for different locations
                const resultArray = Object.entries(totalsByLocation).map(([location, totals]) => ({
                    location: location,
                    totalSales: `$ ${totals.totalSales.toFixed(2)}`,
                    totalOrders: totals.totalOrders,
                }));
                setGiftCardReport(resultArray);
                const seriesName = res.data?.report?.map((item: any) => {
                    return item.name;
                });
                setGiftCardTotal(totalOrders);
                setGiftCardTotalAmount(((res?.data.total_gc_redemption * 1) / 1).toFixed(2));
                setChartYAxisTitle('Total Value in $');
                // const seriesName = ['Applied GiftCard Amount'];

                const seriesData = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        const order = ele.total_gc_redemption.toFixed(2);
                        return { x: ele.time, y: order };
                    });
                });
                setCharData(seriesData);
                setData1(seriesName);
                setChartLoading(false);
                const orderId = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        return ele.order_ids;
                    });
                });
                const flattened = orderId.reduce((acc, current) => acc.concat(...current), []);
                setOrderIds(flattened);
                if (flattened?.length > 0) {
                    const data = await axios
                        .post(`v1/admin/reports/get-report-orders`, {
                            orders: flattened,
                        })
                        .then((data) => {
                            return data;
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    const CsvData = data?.data?.map((ele: any) => {
                        return [
                            ele.order_id,
                            ele.promise_time,
                            ele.creation_date,
                            ele.location_name,
                            ele.order_type,
                            ele.order_mode,
                            ele.sub_total,
                            ele.tax,
                            ele.deliver_fee,
                            ele.tip,
                            ele.discounts,
                            ele.employee_discount,
                            ele.adjustment,
                            ele.refund,
                            ele.gc_redemption,
                            ele.total,
                            ele.net_sales,
                        ];
                    });
                    setCSVDatas(CsvData);
                }
            });
    };
    const getDiscountReport = async (params?: any) => {
        axios
            .get(`v1/admin/reports/get-discount-report?filter=${params ?? filters}`)
            .then(async (res) => {
                setDiscountTotal(((res?.data.total_discounts * 1) / 1).toFixed(2));

                const seriesName = res.data?.report?.map((item: any) => {
                    return item.name;
                });
                setChartYAxisTitle('Total Value in $');
                const resDiscountMod = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        const inputDate = ele.time;
                        const report: any = {};
                        report.location = item.name;
                        report.date = inputDate;
                        report.totalSales = `$ ${ele.total_discounts}`;
                        report.totalOrders = ele.order_count;

                        return report;
                    });
                });
                const flattenedArray = [].concat(...resDiscountMod);
                const totalsByLocation = flattenedArray.reduce((acc: any, current: any) => {
                    const location = current.location.trim();
                    if (!acc[location]) {
                        acc[location] = { totalSales: 0, totalOrders: 0 };
                    }
                    acc[location].totalSales += parseFloat(current.totalSales.replace('$', ''));
                    acc[location].totalOrders += current.totalOrders;
                    return acc;
                }, {});
                let totalOrders: any = 0;
                Object.entries(totalsByLocation).map(([location, totals]) => {
                    totalOrders += totals.totalOrders;
                }); // Create a new array with total sales and total orders for different locations
                const resultArray = Object.entries(totalsByLocation).map(([location, totals]) => ({
                    location: location,
                    totalSales: `$ ${totals.totalSales.toFixed(2)}`,
                    totalOrders: totals.totalOrders,
                }));

                const seriesData = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        const order = ele.total_discounts.toFixed(2);
                        return { x: ele.time, y: order };
                    });
                });
                setTotalUsedDiscount(totalOrders);
                setDiscountReport(resultArray);
                setCharData(seriesData);
                setData1(seriesName);
                setChartLoading(false);
                const orderId = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        return ele.order_ids;
                    });
                });
                const flattened = orderId.reduce((acc, current) => acc.concat(...current), []);
                setOrderIds(flattened);
                if (flattened?.length > 0) {
                    const data = await axios
                        .post(`v1/admin/reports/get-report-orders`, {
                            orders: flattened,
                        })
                        .then((data) => {
                            return data;
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    const CsvData = data?.data?.map((ele: any) => {
                        return [
                            ele.order_id,
                            ele.promise_time,
                            ele.creation_date,
                            ele.location_name,
                            ele.order_type,
                            ele.order_mode,
                            ele.sub_total,
                            ele.tax,
                            ele.deliver_fee,
                            ele.tip,
                            ele.discounts,
                            ele.employee_discount,
                            ele.adjustment,
                            ele.refund,
                            ele.gc_redemption,
                            ele.total,
                            ele.net_sales,
                        ];
                    });
                    setCSVDatas(CsvData);
                }
            });
    };

    const getDeliveryReport = async (params?: any) => {
        axios
            .get(`v1/admin/reports/get-delivery-report?filter=${params ?? filters}`)
            .then(async (res) => {
                setDeliveryTotalAmount(((res?.data.total_deliver_fee * 1) / 1).toFixed(2));

                const seriesName = res.data?.report?.map((item: any) => {
                    return item.name;
                });
                setChartYAxisTitle('Total Deliveries in $');
                const resDeliveryMod = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        const inputDate = ele.time;
                        const report: any = {};
                        report.location = item.name;
                        report.date = inputDate;
                        report.totalSales = `$ ${ele.total_deliver_fee}`;
                        report.totalOrders = ele.order_count;

                        return report;
                    });
                });
                const flattenedArray = [].concat(...resDeliveryMod);
                const totalsByLocation = flattenedArray.reduce((acc: any, current: any) => {
                    const location = current.location.trim();
                    if (!acc[location]) {
                        acc[location] = { totalSales: 0, totalOrders: 0 };
                    }
                    acc[location].totalSales += parseFloat(current.totalSales.replace('$', ''));
                    acc[location].totalOrders += current.totalOrders;
                    return acc;
                }, {});
                let totalOrders: any = 0;
                Object.entries(totalsByLocation).map(([location, totals]) => {
                    totalOrders += totals.totalOrders;
                }); // Create a new array with total sales and total orders for different locations
                const resultArray = Object.entries(totalsByLocation).map(([location, totals]) => ({
                    location: location,
                    totalSales: `$ ${totals.totalSales.toFixed(2)}`,
                    totalOrders: totals.totalOrders,
                }));

                const seriesData = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        const order = ele.total_deliver_fee.toFixed(2);
                        return { x: ele.time, y: order };
                    });
                });
                setDeliveryTotal(totalOrders);
                setDeliveryReport(resultArray);
                setCharData(seriesData);
                setData1(seriesName);
                setChartLoading(false);
                const orderId = res.data?.report?.map((item: any) => {
                    return item.report.map((ele: any) => {
                        return ele.order_ids;
                    });
                });
                const flattened = orderId.reduce((acc, current) => acc.concat(...current), []);
                setOrderIds(flattened);
                if (flattened?.length > 0) {
                    const data = await axios
                        .post(`v1/admin/reports/get-report-orders`, {
                            orders: flattened,
                        })
                        .then((data) => {
                            return data;
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    const CsvData = data?.data?.map((ele: any) => {
                        return [
                            ele.order_id,
                            ele.promise_time,
                            ele.creation_date,
                            ele.location_name,
                            ele.order_type,
                            ele.order_mode,
                            ele.sub_total,
                            ele.tax,
                            ele.deliver_fee,
                            ele.tip,
                            ele.discounts,
                            ele.employee_discount,
                            ele.adjustment,
                            ele.refund,
                            ele.gc_redemption,
                            ele.total,
                            ele.net_sales,
                        ];
                    });
                    setCSVDatas(CsvData);
                }
            });
    };

    const getRedemptionTotal = async (params?: any) => {
        axios
            .get(`v1/admin/reports/get-redemption-report?filter=${params ?? filters}`)
            .then((res) => {
                const totalAmount = res.data?.reduce(
                    (sum: any, item: any) => sum + item.total_amount,
                    0
                );
                const redemptionTotal = res.data?.reduce(
                    (sum: any, item: any) => sum + item.total_item,
                    0
                );
                setRedemptionTotal(redemptionTotal);
                setRedemptionTotalAmount((totalAmount / 100).toFixed(2));
                setChartYAxisTitle('Total Value in $');
                // setSalesReports(res.data);
                const seriesName = ['Total Redemption'];
                const seriesData = res.data?.map((item: any) => {
                    const resRedemptionMod = res.data?.map((item: any) => {
                        const inputDate = item.date;
                        const report: any = {};
                        report.date = inputDate;
                        report.total_amount = `$ ${getDollarPrice(item.total_amount)}`;
                        report.total_order = item.total_item;
                        return report;
                    });
                    setRedemptionReport(resRedemptionMod);

                    const order = item.total_amount / 100;
                    return { x: item.date, y: order };
                });
                console.log(seriesData, 'ChartData::::::');
                setCharData([seriesData]);
                setData1(seriesName);
                setChartLoading(false);
            });
    };
    const getEmployeeMealReport = async (params?: any) => {
        axios
            .get(`v1/admin/reports/get-employee-meal-report?filter=${params ?? filters}`)
            .then((res) => {
                // console.log(res.data);
                setChartYAxisTitle('Total Value in $');
                const totalAmount = res.data?.reduce(
                    (sum: any, item: any) => sum + item.total_sales,
                    0
                );
                const employeeTotal = res.data?.reduce(
                    (sum: any, item: any) => sum + item.total_quantity,
                    0
                );
                setEmployeeTotal(employeeTotal);
                setEmployeeTotalAmount((totalAmount / 100).toFixed(2));
                const seriesName = ['Employee Meal Report'];
                const resEmployeeMod = res.data?.map((item: any) => {
                    const inputDate = item.date;
                    // const parts = inputDate.split('-');
                    // const convertedDate =
                    //     parts.length > 1 ? parts[1] + '/' + parts[0] + '/' + parts[2] : item.date;

                    const report: any = {};
                    report.date = inputDate;
                    report.total_amount = `$ ${getDollarPrice(item.total_sales)}`;
                    report.total_order = item.total_quantity;
                    return report;
                });
                setEmployeeMealReport(resEmployeeMod);
                const seriesData = res.data?.map((item: any) => {
                    const order = (item.total_sales / 100).toFixed(2);
                    return { x: item.date, y: order };
                });
                setCharData([seriesData]);
                setData1(seriesName);
                setChartLoading(false);
            });
    };
    const getProductReport = async (params?: any) => {
        axios.get(`v1/admin/reports/get-product-report?filter=${params ?? filters}`).then((res) => {
            // setDeliveryTotalAmount(((res?.data.total_deliver_fee * 1) / 1).toFixed(2));

            const seriesName = res.data?.report?.map((item: any) => {
                return item.name;
            });
            // setChartYAxisTitle('Total Deliveries in $');

            const resProductMod = res.data?.map((item: any) => {
                return item.report.map((ele: any) => {
                    const inputDate = ele.time;
                    const report: any = {};
                    report.product_Names = item.name;
                    report.date = inputDate;
                    report.totalSales = `$ ${(ele.total_sales / 100).toFixed(2)}`;
                    report.totalOrders = ele.total_quantity;

                    return report;
                });
            });

            const flattenedArray = [].concat(...resProductMod);
            const totalsByLocation = flattenedArray.reduce((acc: any, current: any) => {
                const product_Names = current.product_Names.trim();
                if (!acc[product_Names]) {
                    acc[product_Names] = { totalSales: 0, totalOrders: 0 };
                }
                acc[product_Names].totalSales += parseFloat(current.totalSales.replace('$', ''));
                acc[product_Names].totalOrders += current.totalOrders;
                return acc;
            }, {});
            let totalOrders: any = 0;
            let totalSales: any = 0;
            Object.entries(totalsByLocation).map(([product_Names, totals]) => {
                totalOrders += totals.totalOrders;
                totalSales += totals.totalSales;
            }); // Create a new array with total sales and total orders for different locations
            const resultArray = Object.entries(totalsByLocation).map(([product_Names, totals]) => ({
                product_Names: product_Names,
                totalSales: `$ ${totals.totalSales.toFixed(2)}`,
                totalOrders: totals.totalOrders,
            }));
            const sorted_data = resultArray.sort((a, b) => b.totalOrders - a.totalOrders);
            const csvdata = [
                ...sorted_data.map((ele) => {
                    return [ele.product_Names, ele.totalOrders, ele.totalSales];
                }),
            ];
            setCSVDatas(csvdata);
            setSalesTotal(totalSales.toFixed(2));
            setProductsTotal(totalOrders);
            setProductReport(sorted_data);
            // setCharData(seriesData);
            setData2(seriesName);
        });
    };
    const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
    }
    .charts {
        align :center
    }`;
    const titlestyle = {
        fontFamily: 'Inter var',
        fontWeight: 'regular',
        size: '20px',
    };
    const onChartLoad = (args) => {
        const chart = document.getElementById('charts');
        chart.setAttribute('title', '');
    };
    const load = (args) => {
        let selectedTheme = location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.chart.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
            .replace(/-dark/i, 'Dark')
            .replace(/contrast/i, 'Contrast');
    };

    useEffect(() => {
        getTotalSales();
        // getTotalSalesByMode();
        // getTotalSalesByType();
        //getGiftCardReport();
        //getDiscountReport()
        // getDeliveryReport();
        // getRedemptionTotal();
        // getEmployeeMealReport();
    }, []);

    // function for getting the dollar price
    const getDollarPrice = (priceInCents) => {
        if (priceInCents == 0) {
            return priceInCents;
        } else if (priceInCents == '-') {
            return '0.00';
        } else {
            return (priceInCents / 100).toFixed(2);
        }
    };
    //////////

    const handleChange = (newValue) => {
        const a = parseInt(newValue.target.id);
        setValue(a);
    };

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };
    useEffect(() => {
        // getReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close]);

    const filteredProductReport = productReport?.filter((report: Report) => {
        if (searchVal || locationFilter) {
            if (report?.location_name === locationFilter) {
                return report;
            }
            if (searchVal) {
                return report?.location_name?.toLowerCase()?.includes(searchVal.toLowerCase());
            }
        } else {
            return report;
        }
    });

    const filteredSalesReport = salesReport?.filter((report: Report) => {
        if (searchVal || locationFilter) {
            if (report?.location === locationFilter) {
                return report;
            }
            if (searchVal) {
                return report?.location_name?.toLowerCase()?.includes(searchVal.toLowerCase());
            }
        } else {
            return report;
        }
    });
    const filteredSalesReportByMode = salesReportByMode?.filter((report: Report) => {
        return report;
    });
    const filteredSalesReportByType = salesReportByType?.filter((report: Report) => {
        return report;
    });

    // const filteredProductReport = productReport?.filter((report: Report) => {
    //     if (searchVal || locationFilter) {
    //         if (searchVal) {
    //             return report?.product_name?.toLowerCase()?.includes(searchVal.toLowerCase());
    //         }
    //     } else {
    //         return report;
    //     }
    // });

    const filteredDiscountReport = discountReport?.filter((report: Report) => {
        return report;
    });

    const filteredDeliveryReport = deliveryReport?.filter((report: Report) => {
        return report;
    });
    const filteredGiftCardReport = giftCardReport?.filter((report: Report) => {
        return report;
    });
    const filteredRedemptionReport = redemptionReport?.filter((report: Report) => {
        return report;
    });

    const filteredEmployeeMealReport = employeeMealReport?.filter((report: Report) => {
        return report;
    });

    const handleDelete = async (reportData: Report) => {};

    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case 'add-target':
                setaddTarget(data);
                setSendTarget(null);
                open();
                break;
            case 'send-target':
                setaddTarget(null);
                setSendTarget(data);
                open();
                break;
            case 'delete':
                setConfirmMsg(data);
                break;
            // case 'view':
            //     setviewReport(true);
            //     open();
            //     break;
            default:
                return null;
        }
    };

    const closeDialog = () => {
        setviewReport(null);
        setaddTarget(null);
        setSendTarget(null);
        close();
    };

    // const LocationOptions = new Set(salesReport?.map((item) => item.location_name));

    function TabPanel(props: any) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: any) {
        return {
            id: index,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <ContentLayout
            title=""
            topNavBarData={{
                num:
                    value == 0
                        ? taxReport.length
                        : value == 1
                        ? salesReport.length
                        : value == 2
                        ? productReport.length
                        : value == 3
                        ? discountReport.length
                        : deliveryReport.length,
                title: 'Reports',
            }}
        >
            {confirmMsg ? (
                <Confirm
                    btnTitle={'Delete'}
                    onClose={() => setConfirmMsg(null)}
                    onSuccess={() => handleDelete(confirmMsg)}
                />
            ) : null}

            <div className="table-header">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="flex">
                                {/* <div className="input-group search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        value={searchVal}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div> */}
                                <div className="form-group pos-dev">
                                    <select
                                        className="form-control ml-1 w-auto pos-select"
                                        name="location"
                                        value={locationFilter}
                                        onChange={(e) => setLocationFilter(e.target.value.trim())}
                                    >
                                        <option value="">Select Location</option>
                                        {data2?.map((location_name) => (
                                            <option key={location_name} value={location_name}>
                                                {location_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="add-btn" style={{ float: 'right' }}>
                                <Button
                                    startIcon={<AddCircleOutline />}
                                    onClick={() => {
                                        selectedUserAction({
                                            action: 'add-target',
                                            data: 'add-target',
                                        });
                                    }}
                                >
                                    Targets
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {/* --------- Charts Section ----------- */}
            <div className="row promise-time mb-4 align-center justify-center">
                <div className="col-12 col-md-6">
                    <div className="border-solid border p-3 rounded">
                        <div className="flex gap-3">
                            <div className="flex-1">
                                <div className="pb-2 flex justify-between items-center">
                                    <h4 className="mb-0">
                                        <strong>Total Sales</strong>
                                    </h4>
                                    <div className="text-sm">
                                        {orderTotal} Orders /{' '}
                                        <span
                                            style={{ color: '#FFAF60' }}
                                            className="text-xl font-bold"
                                        >
                                            ${salesReports ? salesReports : 0}
                                        </span>
                                    </div>
                                </div>
                                <hr />
                                <div className="flex justify-between items-center">
                                    <div className="flex gap-1 pt-3">
                                        <div
                                            className={
                                                filters == 'Today'
                                                    ? 'chart-button-filled'
                                                    : 'chart-button'
                                            }
                                            onClick={() => {
                                                setFilters('Today');
                                                setCharData([]);
                                                setData1([]);
                                                if (value == 0) {
                                                    getTotalSales('Today');
                                                } else if (value == 1) {
                                                    getTotalSalesByMode('Today');
                                                } else if (value == 2) {
                                                    getTotalSalesByType('Today');
                                                } else if (value == 3) {
                                                    getGiftCardReport('Today');
                                                } else if (value == 4) {
                                                    getDiscountReport('Today');
                                                } else if (value == 5) {
                                                    getDeliveryReport('Today');
                                                } else if (value == 6) {
                                                    getRedemptionTotal('Today');
                                                } else if (value == 7) {
                                                    getEmployeeMealReport('Today');
                                                } else if (value == 8) {
                                                    getProductReport('Today');
                                                }
                                            }}
                                        >
                                            Today
                                        </div>
                                        <div
                                            className={
                                                filters == 'Week'
                                                    ? 'chart-button-filled'
                                                    : 'chart-button'
                                            }
                                            onClick={() => {
                                                setFilters('Week');
                                                setCharData([]);
                                                setData1([]);
                                                if (value == 0) {
                                                    getTotalSales('Week');
                                                } else if (value == 1) {
                                                    getTotalSalesByMode('Week');
                                                } else if (value == 2) {
                                                    getTotalSalesByType('Week');
                                                } else if (value == 3) {
                                                    getGiftCardReport('Week');
                                                } else if (value == 4) {
                                                    getDiscountReport('Week');
                                                } else if (value == 5) {
                                                    getDeliveryReport('Week');
                                                } else if (value == 6) {
                                                    getRedemptionTotal('Week');
                                                } else if (value == 7) {
                                                    getEmployeeMealReport('Week');
                                                } else if (value == 8) {
                                                    getProductReport('Week');
                                                }
                                            }}
                                        >
                                            This Week
                                        </div>
                                        <div
                                            className={
                                                filters == 'Month'
                                                    ? 'chart-button-filled'
                                                    : 'chart-button'
                                            }
                                            onClick={() => {
                                                setFilters('Month');
                                                setCharData([]);
                                                setData1([]);
                                                if (value == 0) {
                                                    getTotalSales('Month');
                                                } else if (value == 1) {
                                                    getTotalSalesByMode('Month');
                                                } else if (value == 2) {
                                                    getTotalSalesByType('Month');
                                                } else if (value == 3) {
                                                    getGiftCardReport('Month');
                                                } else if (value == 4) {
                                                    getDiscountReport('Month');
                                                } else if (value == 5) {
                                                    getDeliveryReport('Month');
                                                } else if (value == 6) {
                                                    getRedemptionTotal('Month');
                                                } else if (value == 7) {
                                                    getEmployeeMealReport('Month');
                                                } else if (value == 8) {
                                                    getProductReport('Month');
                                                }
                                            }}
                                        >
                                            This Month
                                        </div>
                                    </div>
                                    <div className="pt-3 text-sm date-format">
                                        <span>{moment().format('MM-DD-YYYY')}</span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="">
                                <div style={{ width: 130, height: 100, color: '#fff' }}>
                                    <CircularProgressbar
                                        value={basicReport?.promiseTimeEfficiency?.ratio || 0}
                                        text={`${basicReport?.promiseTimeEfficiency?.ratio || 0}%`}
                                        circleRatio={0.5}
                                        styles={buildStyles({
                                            rotation: 0.75,
                                            strokeLinecap: 'round',
                                            textSize: '18px',
                                            pathTransitionDuration: 0.5,
                                            pathColor: '#FFAF60',
                                            textColor: '#FFAF60',
                                            trailColor: '#d6d6d6',
                                            backgroundColor: '#FFAF60',
                                        })}
                                    />
                                    ;
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* --------- Charts Section ----------- */}

            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        borderBottom: 0,
                        padding: '0',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className="prTabs report-dash-tabs"
                        style={{ paddingTop: '10px' }}
                    >
                        <Tab
                            label="Sales Report"
                            className="right-report"
                            value={[0, 1, 2]?.includes(value) ? value : 'none'}
                            {...a11yProps(0)}
                            onClick={() => {
                                setCharData([]);
                                setData1([]);
                                getTotalSales();
                                setChartLoading(true);
                                setChartTitle('Sales Report By Location');
                            }}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '0px 6px 0 0',
                                width: '14.28%',

                                // maxWidth: '16.66%',
                            }}
                        />

                        <Tab
                            label="Gift Card Report"
                            className="right-report"
                            value={3}
                            onClick={() => {
                                setCharData([]);
                                setData1([]);
                                getGiftCardReport();
                                setChartLoading(true);
                                setChartTitle('Gift Cards Report');
                            }}
                            {...a11yProps(3)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '0px 6px 0 0',
                                width: '14.28%',

                                // maxWidth: '10%',
                            }}
                        />
                        <Tab
                            label="Promo Codes"
                            className="right-report"
                            value={4}
                            onClick={() => {
                                setCharData([]);
                                setData1([]);
                                getDiscountReport();
                                setChartLoading(true);
                                setChartTitle('Promo Codes Report');
                            }}
                            {...a11yProps(4)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '0px 6px 0 0',
                                width: '14.28%',

                                // maxWidth: '10%',
                            }}
                        />
                        <Tab
                            label="Delivery Report"
                            className="right-report"
                            value={5}
                            onClick={() => {
                                setCharData([]);
                                setData1([]);
                                getDeliveryReport();
                                setChartLoading(true);
                                setChartTitle('Delivery Report');
                            }}
                            {...a11yProps(5)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '0px 6px 0 0',
                                width: '14.28%',

                                // maxWidth: '10%',
                            }}
                        />
                        <Tab
                            label="Redemption Report"
                            className="right-report"
                            value={6}
                            onClick={() => {
                                setCharData([]);
                                setData1([]);
                                getRedemptionTotal();
                                setChartLoading(true);
                                setChartTitle('Redemptions Report');
                            }}
                            {...a11yProps(6)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '0px 6px 0 0',
                                width: '14.28%',

                                // maxWidth: '10%',
                            }}
                        />
                        <Tab
                            label="Employee Meal Report"
                            className="left-report"
                            value={7}
                            onClick={() => {
                                setCharData([]);
                                setData1([]);
                                getEmployeeMealReport();
                                setChartLoading(true);
                                setChartTitle('Employee Meal Report');
                            }}
                            {...a11yProps(7)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '6px 0px 0 0',
                                width: '14.28%',

                                // maxWidth: '10%',
                            }}
                        />
                        <Tab
                            label="Product Report"
                            className="left-report"
                            value={8}
                            onClick={() => {
                                setCharData([]);
                                setData1([]);
                                getProductReport();
                            }}
                            {...a11yProps(8)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '6px 0px 0 0',
                                width: '14.28%',

                                // maxWidth: '10%',
                            }}
                        />
                    </Tabs>
                </Box>
                <Box
                    sx={{
                        display: [0, 1, 2]?.includes(value) ? 'flex' : 'none',
                        alignItems: 'center',
                        justifyContent: 'flex-end !important',
                        width: '100%',
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className="prTabs report-dash-tabs"
                        sx={{
                            paddingTop: '10px',
                            width: '50%',
                            // border: '1px solid',

                            'css-1tk2hpi-MuiTabs-root': {
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end !important',
                            },
                        }}
                    >
                        <Tab
                            label=" Location"
                            className="right-report"
                            value={0}
                            {...a11yProps(0)}
                            onClick={() => {
                                setCharData([]);
                                setData1([]);
                                getTotalSales();
                                setChartLoading(true);
                                setChartTitle('Total Sales Report By Location');
                            }}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '0px 6px 0 0',
                                width: '32%',

                                // maxWidth: '16.66%',
                            }}
                        />
                        <Tab
                            label=" Mode"
                            className="right-report"
                            value={1}
                            onClick={() => {
                                setCharData([]);
                                setData1([]);
                                getTotalSalesByMode();
                                setChartLoading(true);
                                setChartTitle('Total Sales Report By Mode');
                            }}
                            {...a11yProps(1)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '0px 6px 0 0',
                                width: '32%',
                                marginLeft: '10px',
                                marginRight: '10px',

                                // maxWidth: '16.66%',
                            }}
                        />
                        <Tab
                            label="Type"
                            className="right-report"
                            value={2}
                            onClick={() => {
                                setCharData([]);
                                setData1([]);
                                getTotalSalesByType();
                                setChartLoading(true);
                                setChartTitle('Total Sales Report By Type');
                            }}
                            {...a11yProps(2)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '0px 6px 0 0',
                                width: '32%',
                                // maxWidth: '16.66%',
                            }}
                        />
                    </Tabs>
                </Box>
                <br></br>
                {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}
                {/* <Spinner size="lg" className="text-white z-10" /> */}

                {value != 8 ? (
                    <ChartComponent
                        className="font-sans z-0 relative"
                        id="charts"
                        style={{ textAlign: 'center' }}
                        primaryXAxis={{
                            valueType: 'DateTime',
                            edgeLabelPlacement: 'Shift',
                            majorGridLines: { width: 0 },
                            labelFormat: filters == 'Today' ? 'hh:mm:a' : 'M/d/y',
                        }}
                        load={load.bind(this)}
                        primaryYAxis={{
                            title: chartYAxisTitle,
                            rangePadding: 'None',
                            // minimum: 0,
                            // maximum: 20,
                            // interval: 4,
                            lineStyle: { width: 0 },
                            majorTickLines: { width: 0 },
                            minorTickLines: { width: 0 },
                        }}
                        titleStyle={titlestyle}
                        chartArea={{ border: { width: 0 } }}
                        tooltip={{ enable: true, format: '${point.x} : $ ${point.y}' }}
                        legendSettings={{ enableHighlight: true }}
                        width={Browser.isDevice ? '100%' : '100%'}
                        title={chartTitle}
                        loaded={onChartLoad.bind(this)}
                    >
                        <Inject services={[LineSeries, DateTime, Legend, Tooltip, Highlight]} />

                        {chartLoading ? (
                            <div className="w-full h-full flex justify-center items-center  opacity-5 absolute top-[50%] left-[50%] ">
                                <Spinner size="lg" />
                            </div>
                        ) : null}

                        <SeriesCollectionDirective>
                            <SeriesDirective
                                dataSource={charData[1]}
                                xName="x"
                                yName="y"
                                name={data1[1]}
                                width={2}
                                marker={{
                                    visible: true,
                                    width: 7,
                                    height: 7,
                                    shape: 'Circle',
                                    isFilled: true,
                                }}
                                type="Line"
                            ></SeriesDirective>
                            <SeriesDirective
                                dataSource={charData[0]}
                                xName="x"
                                yName="y"
                                name={data1[0]}
                                width={2}
                                marker={{
                                    visible: true,
                                    width: 6,
                                    height: 6,
                                    shape: 'Triangle',
                                    isFilled: true,
                                }}
                                type="Line"
                            ></SeriesDirective>
                            <SeriesDirective
                                dataSource={charData[2]}
                                xName="x"
                                yName="y"
                                name={data1[2]}
                                width={2}
                                marker={{
                                    visible: true,
                                    width: 7,
                                    height: 7,
                                    shape: 'Diamond',
                                    isFilled: true,
                                }}
                                type="Line"
                            ></SeriesDirective>
                            <SeriesDirective
                                dataSource={charData[3]}
                                xName="x"
                                yName="y"
                                name={data1[3]}
                                width={2}
                                marker={{
                                    visible: true,
                                    width: 5,
                                    height: 5,
                                    shape: 'Rectangle',
                                    isFilled: true,
                                }}
                                type="Line"
                            ></SeriesDirective>
                            <SeriesDirective
                                dataSource={charData[4]}
                                xName="x"
                                yName="y"
                                name={data1[4]}
                                width={2}
                                marker={{
                                    visible: true,
                                    width: 7,
                                    height: 7,
                                    shape: 'Pentagon',
                                    isFilled: true,
                                }}
                                type="Line"
                            ></SeriesDirective>
                            <SeriesDirective
                                dataSource={charData[5]}
                                xName="x"
                                yName="y"
                                name={data1[5]}
                                width={2}
                                marker={{
                                    visible: true,
                                    width: 7,
                                    height: 7,
                                    shape: 'hexagon',
                                    isFilled: true,
                                }}
                                type="Line"
                            ></SeriesDirective>
                            <SeriesDirective
                                dataSource={charData[6]}
                                xName="x"
                                yName="y"
                                name={data1[6]}
                                width={2}
                                marker={{
                                    visible: true,
                                    width: 7,
                                    height: 7,
                                    shape: 'heptagon',
                                    isFilled: true,
                                }}
                                type="Line"
                            ></SeriesDirective>
                            <SeriesDirective
                                dataSource={charData[7]}
                                xName="x"
                                yName="y"
                                name={data1[7]}
                                width={2}
                                marker={{
                                    visible: true,
                                    width: 7,
                                    height: 7,
                                    shape: 'Octagon',
                                    isFilled: true,
                                }}
                                type="Line"
                            ></SeriesDirective>
                            <SeriesDirective
                                dataSource={charData[8]}
                                xName="x"
                                yName="y"
                                name={data1[8]}
                                width={2}
                                marker={{
                                    visible: true,
                                    width: 7,
                                    height: 7,
                                    shape: 'nonagon',
                                    isFilled: true,
                                }}
                                type="Line"
                            ></SeriesDirective>
                            <SeriesDirective
                                dataSource={charData[9]}
                                xName="x"
                                yName="y"
                                name={data1[9]}
                                width={2}
                                marker={{
                                    visible: true,
                                    width: 7,
                                    height: 7,
                                    shape: 'decagon',
                                    isFilled: true,
                                }}
                                type="Line"
                            ></SeriesDirective>
                            <SeriesDirective
                                dataSource={charData[10]}
                                xName="x"
                                yName="y"
                                name={data1[10]}
                                width={2}
                                marker={{
                                    visible: true,
                                    width: 7,
                                    height: 7,
                                    shape: 'hendecagon',
                                    isFilled: true,
                                }}
                                type="Line"
                            ></SeriesDirective>
                        </SeriesCollectionDirective>
                    </ChartComponent>
                ) : null}
                <TabPanel className="report-tab-panel" value={value} index={8}>
                    <CustomTable
                        tableData={filteredProductReport}
                        headCells={HeadCellsTax}
                        dataFields={dataFieldsTax}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />

                    <div className="min-w-full divide-y divide-gray-200">
                        <div className="bg-gray-400">
                            <div className="row total-bar flex items-center">
                                <div className="col-5 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>Total</strong>
                                </div>

                                <div className="col-3 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    {productsTotal || 0}
                                </div>
                                <div className="col-1 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>${salesTotal || 0}</strong>
                                </div>
                                {/* {orderIds?.length > 0 ? ( */}
                                <div className="col-3 px-6 py-3 text-xs font-medium tracking-wider text-center flex text-white items-center justify-end  uppercase">
                                    <Button>
                                        <CSVLink
                                            className=" text-center font-[400] mb-4 cursor-pointer"
                                            data={finalcsvDatas}
                                            separator={';'}
                                            filename="Health_Nut_Generated_Reports.csv"
                                        >
                                            Download Report
                                        </CSVLink>
                                    </Button>
                                </div>
                                {/* ) : null} */}
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel className="report-tab-panel" value={value} index={0}>
                    <CustomTable
                        tableData={filteredSalesReport}
                        headCells={HeadCellsSale}
                        dataFields={dataFieldsSale}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />
                    <div className="min-w-full divide-y divide-gray-200">
                        <div className="bg-gray-400">
                            <div className="row total-bar flex items-center">
                                <div className="col-4 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>Total</strong>
                                </div>

                                <div className="col-4 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    {orderTotal || 0}
                                </div>
                                <div className="col-1 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>${salesReports || 0}</strong>
                                </div>
                                {orderIds?.length > 0 ? (
                                    <div className="col-3 px-6 py-3 text-xs font-medium tracking-wider text-center flex text-white items-center justify-end  uppercase">
                                        <Button>
                                            <CSVLink
                                                className=" text-center font-[400] mb-4 cursor-pointer"
                                                data={finalcsvDatas}
                                                separator={';'}
                                                filename="Health_Nut_Generated_Reports.csv"
                                            >
                                                Download Report
                                            </CSVLink>
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel className="report-tab-panel" value={value} index={1}>
                    <CustomTable
                        tableData={filteredSalesReportByMode}
                        headCells={HeadCellsSaleByMode}
                        dataFields={dataFieldsSaleByMode}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />
                    <div className="min-w-full divide-y divide-gray-200">
                        <div className="bg-gray-400">
                            <div className="row total-bar flex items-center">
                                <div className="col-4 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>Total</strong>
                                </div>

                                <div className="col-4 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    {orderTotal || 0}
                                </div>
                                <div className="col-1 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>${salesReports || 0}</strong>
                                </div>
                                {orderIds?.length > 0 ? (
                                    <div className="col-3 px-6 py-3 text-xs font-medium tracking-wider text-center flex text-white items-center justify-end  uppercase">
                                        <Button>
                                            <CSVLink
                                                className=" text-center font-[400] mb-4 cursor-pointer"
                                                data={finalcsvDatas}
                                                separator={';'}
                                                filename="Health_Nut_Generated_Reports.csv"
                                            >
                                                Download Report
                                            </CSVLink>
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel className="report-tab-panel" value={value} index={2}>
                    <CustomTable
                        tableData={filteredSalesReportByType}
                        headCells={HeadCellsSaleByType}
                        dataFields={dataFieldsSaleByType}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />
                    <div className="min-w-full divide-y divide-gray-200">
                        <div className="bg-gray-400">
                            <div className="row total-bar flex items-center">
                                <div className="col-4 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>Total</strong>
                                </div>

                                <div className="col-4 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    {orderTotal || 0}
                                </div>
                                <div className="col-1 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>${salesReports || 0}</strong>
                                </div>
                                {orderIds?.length > 0 ? (
                                    <div className="col-3 px-6 py-3 text-xs font-medium tracking-wider text-center flex text-white items-center justify-end  uppercase">
                                        <Button>
                                            <CSVLink
                                                className=" text-center font-[400] mb-4 cursor-pointer"
                                                data={finalcsvDatas}
                                                separator={';'}
                                                filename="Health_Nut_Generated_Reports.csv"
                                            >
                                                Download Report
                                            </CSVLink>
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel className="report-tab-panel" value={value} index={3}>
                    <CustomTable
                        tableData={filteredGiftCardReport}
                        headCells={HeadCellsGiftCard}
                        dataFields={dataFieldsGiftCard}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />
                    <div className="min-w-full divide-y divide-gray-200">
                        <div className="bg-gray-400">
                            <div className="row total-bar flex items-center">
                                <div className="col-4 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>Total</strong>
                                </div>

                                <div className="col-4 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    {giftCardTotal || 0}
                                </div>
                                <div className="col-1 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>${giftCardTotalAmount || 0}</strong>
                                </div>
                                {orderIds?.length > 0 ? (
                                    <div className="col-3 px-6 py-3 text-xs font-medium tracking-wider text-center flex text-white items-center justify-end  uppercase">
                                        <Button>
                                            <CSVLink
                                                className=" text-center font-[400] mb-4 cursor-pointer"
                                                data={finalcsvDatas}
                                                separator={';'}
                                                filename="Health_Nut_Generated_Reports.csv"
                                            >
                                                Download Report
                                            </CSVLink>
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel className="report-tab-panel" value={value} index={4}>
                    <CustomTable
                        tableData={filteredDiscountReport}
                        headCells={HeadCellsDiscount}
                        dataFields={dataFieldsDiscount}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />
                    <div className="min-w-full divide-y divide-gray-200">
                        <div className="bg-gray-400">
                            <div className="row total-bar flex items-center">
                                <div className="col-4 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>Total</strong>
                                </div>

                                <div className="col-4 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    {totalUsedDiscount || 0}
                                </div>
                                <div className="col-1 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>${discountTotal || 0}</strong>
                                </div>
                                {orderIds?.length > 0 ? (
                                    <div className="col-3 px-6 py-3 text-xs font-medium tracking-wider text-center flex text-white items-center justify-end  uppercase">
                                        <Button>
                                            <CSVLink
                                                className=" text-center font-[400] mb-4 cursor-pointer"
                                                data={finalcsvDatas}
                                                separator={';'}
                                                filename="Health_Nut_Generated_Reports.csv"
                                            >
                                                Download Report
                                            </CSVLink>
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel className="report-tab-panel" value={value} index={5}>
                    <CustomTable
                        tableData={filteredDeliveryReport}
                        headCells={HeadCellsDelivery}
                        dataFields={dataFieldsDelivery}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />
                    <div className="min-w-full divide-y divide-gray-200">
                        <div className="bg-gray-400">
                            <div className="row total-bar flex items-center">
                                <div className="col-4 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>Total</strong>
                                </div>

                                <div className="col-4 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    {deliveryTotal || 0}
                                </div>
                                <div className="col-1 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>${deliveryTotalAmount || 0}</strong>
                                </div>
                                {orderIds?.length > 0 ? (
                                    <div className="col-3 px-6 py-3 text-xs font-medium tracking-wider text-center flex text-white items-center justify-end  uppercase">
                                        <Button>
                                            <CSVLink
                                                className=" text-center font-[400] mb-4 cursor-pointer"
                                                data={finalcsvDatas}
                                                separator={';'}
                                                filename="Health_Nut_Generated_Reports.csv"
                                            >
                                                Download Report
                                            </CSVLink>
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel className="report-tab-panel" value={value} index={6}>
                    <CustomTable
                        tableData={filteredRedemptionReport}
                        headCells={HeadCellsRedemption}
                        dataFields={dataFieldsRedemption}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />
                    <div className="min-w-full divide-y divide-gray-200">
                        <div className="bg-gray-400">
                            <div className="row total-bar">
                                <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>Total</strong>
                                </div>

                                <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    {redemptionTotal || 0}
                                </div>
                                <div className="col-md-6 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>${redemptionTotalAmount || 0}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel className="report-tab-panel" value={value} index={7}>
                    <CustomTable
                        tableData={filteredEmployeeMealReport}
                        headCells={HeadCellsEmployee}
                        dataFields={dataFieldsEmployee}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />
                    <div className="min-w-full divide-y divide-gray-200">
                        <div className="bg-gray-400">
                            <div className="row total-bar">
                                <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>Total</strong>
                                </div>

                                <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    {employeeTotal || 0}
                                </div>
                                <div className="col-md-6 px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase">
                                    <strong>${employeeTotalAmount || 0}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </Box>

            <Dialog isOpen={isOpen} onClose={closeDialog} paperWidth={'45%'}>
                <div className="modals-contents">
                    {viewReport && (
                        <ViewReport
                            inquiryData={undefined}
                            closeDialog={undefined}
                            selectedUserAction={undefined}
                            // userData={productData}
                            // closeDialog={closeDialog}
                            // openEdit={openEdit}
                            // openProducts={openProducts}
                            // openMenus={openMenus}
                            // setConfirmMsg={setConfirmMsg}
                        />
                    )}
                    {addTarget && (
                        <AddTarget
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {sendTarget && (
                        <SendTarget
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                            sendTargetData={sendTarget}
                        />
                    )}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
