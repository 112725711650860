import { useEffect, useState } from 'react';

import { useOrders } from '../api/CRUD/getAllOrders';
import AddInvoice from '../components/AddInvoice';
import CreateOrder from '../components/create';
import EditOrder from '../components/edit';
import Refund from '../components/Refund';
import { TableHeader } from '../components/TableHeader';
import { BodyCells, dataFields, HeadCells } from '../constant';
import { Order } from '../type';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { Dialog, Spinner } from '@/components/Elements';

import '../styles/styles.css';

export const Orders = () => {
    const ordersQuery: any = useOrders();
    const { close, open, isOpen } = useDisclosure();

    const [searchVal, setSearchVal] = useState<string>('');
    //const [NoOfCorps, setNoOfCorps] = useState<number>(0);
    const [checkboxFilter, setCheckboxFilter] = useState<string>('all');
    const [statusFilter, setStatusFilter] = useState<string>('');
    const [createOrder, setCreateOrder] = useState<boolean>(true);
    const [editOrder, setEditOrder] = useState<Order | null>(null);
    const [refundOrder, setRefundOrder] = useState<Order | null>(null);
    //const [OrderId, setOrderId] = useState<null | number>(null);
    const [invoice, setInvoice] = useState<null | number>(null);

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    //async function getNoOfCorps() {
    //    ordersQuery?.data?.length > 0 && setNoOfCorps(ordersQuery?.data?.length);
    //}
    useEffect(() => {
        //getNoOfCorps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ordersQuery.data, ordersQuery.isLoading, close]);

    if (ordersQuery.isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!ordersQuery.data) return null;

    const filteredEmployees = ordersQuery?.data?.filter((order: any) => {
        if (statusFilter.length !== 0) {
            if (order?.order_type === checkboxFilter && order?.order_status === statusFilter) {
                if (searchVal !== '') {
                    const firstName = order?.first_name;
                    const locationName = order?.location_name;
                    const orderStatus = order?.order_status;

                    if (
                        (typeof firstName === 'string' &&
                            firstName.toLowerCase().includes(searchVal.toLowerCase())) ||
                        (typeof locationName === 'string' &&
                            locationName.toLowerCase().includes(searchVal.toLowerCase())) ||
                        (typeof orderStatus === 'string' &&
                            orderStatus.toLowerCase().includes(searchVal.toLowerCase()))
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            } else if (checkboxFilter === 'all' && order?.order_status === statusFilter) {
                if (searchVal !== '') {
                    const firstName = order?.first_name;
                    const locationName = order?.location_name;
                    const orderStatus = order?.order_status;

                    if (
                        (typeof firstName === 'string' &&
                            firstName.toLowerCase().includes(searchVal.toLowerCase())) ||
                        (typeof locationName === 'string' &&
                            locationName.toLowerCase().includes(searchVal.toLowerCase())) ||
                        (typeof orderStatus === 'string' &&
                            orderStatus.toLowerCase().includes(searchVal.toLowerCase()))
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            }
        } else {
            if (order?.order_type.toLowerCase() === checkboxFilter.toLowerCase()) {
                if (searchVal !== '') {
                    const firstName = order?.first_name;
                    const locationName = order?.location_name;
                    const orderStatus = order?.order_status;

                    if (
                        (typeof firstName === 'string' &&
                            firstName.toLowerCase().includes(searchVal.toLowerCase())) ||
                        (typeof locationName === 'string' &&
                            locationName.toLowerCase().includes(searchVal.toLowerCase())) ||
                        (typeof orderStatus === 'string' &&
                            orderStatus.toLowerCase().includes(searchVal.toLowerCase()))
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            } else if (checkboxFilter.toLowerCase() === 'all') {
                if (searchVal !== '') {
                    const firstName = order?.first_name;
                    const locationName = order?.location_name;
                    const orderStatus = order?.order_status;

                    if (
                        (typeof firstName === 'string' &&
                            firstName.toLowerCase().includes(searchVal.toLowerCase())) ||
                        (typeof locationName === 'string' &&
                            locationName.toLowerCase().includes(searchVal.toLowerCase())) ||
                        (typeof orderStatus === 'string' &&
                            orderStatus.toLowerCase().includes(searchVal.toLowerCase()))
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            }
        }
    });

    const handleDelete = (corpData: Order) => {
        //editUser({
        //    data: {
        //        ...userData,
        //        user_active: false,
        //    },
        //});
        //const newUsersList = data.filter((e) => e.id !== userData.id);
        //setUsersList(newUsersList);
    };

    const selectedUserAction = ({ action, data }: any) => {
        switch (action) {
            case 'create':
                setRefundOrder(null);
                setCreateOrder(true);
                setEditOrder(null);
                setInvoice(null);
                open();
                break;
            case 'view':
                setRefundOrder(null);
                setCreateOrder(false);
                setEditOrder(data);
                setInvoice(null);
                open();
                break;
            case 'refund':
                setEditOrder(null);
                setCreateOrder(false);
                setRefundOrder(data);
                setInvoice(null);
                open();
                break;
            case 'add-invoice':
                setEditOrder(null);
                setRefundOrder(null);
                setCreateOrder(false);
                setInvoice(data);
                open();
                break;
            case 'remove':
                handleDelete(data);
                break;
            default:
                return null;
        }
    };
    const closeDialog = () => {
        setEditOrder(null);
        setRefundOrder(null);
        setCreateOrder(false);
        setInvoice(null);
        close();
    };
    return (
        <ContentLayout title="" topNavBarData={{ num: ordersQuery?.data?.length, title: 'Orders' }}>
            {TableHeader(
                searchVal,
                handleSearch,
                statusFilter,
                setStatusFilter,
                checkboxFilter,
                setCheckboxFilter,
                selectedUserAction
            )}
            <CustomTable
                tableData={filteredEmployees}
                headCells={HeadCells}
                dataFields={dataFields}
                selectedUserAction={selectedUserAction}
                bodyCells={BodyCells}
                rowOptions={true}
                optionMenu={['refund', 'remove']}
            />
            <Dialog isOpen={isOpen} onClose={close} paperWidth={'fit-content'}>
                <div>
                    {createOrder && (
                        <CreateOrder
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {editOrder && (
                        <EditOrder
                            ordersQuery={ordersQuery}
                            orderData={editOrder}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {refundOrder && <Refund orderData={refundOrder} closeDialog={closeDialog} />}
                    {invoice && <AddInvoice data={invoice} closeDialog={closeDialog} />}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
