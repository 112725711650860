export const HeadCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: '#',
        sort: true,
        type: 'text',
    },
    {
        id: 'serial_number',
        numeric: false,
        disablePadding: false,
        label: 'Serial Number',
        sort: false,
        type: 'text',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Device Name',
        sort: false,
        type: 'text',
    },
    {
        id: 'location_name',
        numeric: false,
        disablePadding: false,
        label: 'Location Name',
        sort: true,
        type: 'text',
    },
    {
        id: 'app_version',
        numeric: true,
        disablePadding: false,
        label: 'App Version',
        sort: true,
        type: 'text',
    },
    {
        id: 'created_at',
        numeric: true,
        disablePadding: false,
        label: 'Created',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'updated_at',
        numeric: true,
        disablePadding: false,
        label: 'Last Update',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Active',
        sort: true,
        type: 'boolean',
    },
];
export const dataFields = [
    'id',
    'serial_number',
    'name',
    'location_name',
    'app_version',
    'created_at',
    'updated_at',
    'status'
];
