export const HeadCellsTax = [
    // {
    //     id: 'id',
    //     numeric: true,
    //     disablePadding: true,
    //     label: '#',
    //     sort: true,
    //     type: 'text',
    // },
    {
        id: 'product_Names',
        numeric: false,
        disablePadding: false,
        label: 'Product Name',
        sort: false,
        type: 'text',
    },
    {
        id: 'totalOrders',
        numeric: false,
        disablePadding: false,
        label: 'Qty of Items',
        sort: false,
        type: 'number',
    },
    {
        id: 'totalSales',
        numeric: true,
        disablePadding: false,
        label: 'Total Sales',
        sort: false,
        type: 'number',
    },
];

export const HeadCellsSale = [
    {
        id: 'location',
        numeric: false,
        disablePadding: true,
        label: 'Location',
        sort: false,
        type: 'text',
    },
    // {
    //     id: 'date',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Date',
    //     sort: false,
    //     type: 'date',
    // },
    {
        id: 'totalOrders',
        numeric: false,
        disablePadding: false,
        label: 'Qty of Orders',
        sort: false,
        type: 'number',
    },
    {
        id: 'totalSales',
        numeric: true,
        disablePadding: false,
        label: 'Total Sales',
        sort: true,
        type: 'number',
    },
    {
        id: 'average',
        numeric: true,
        disablePadding: false,
        label: 'Avg/Order',
        sort: true,
        type: 'number',
    },
];
export const HeadCellsSaleByType = [
    {
        id: 'type',
        numeric: false,
        disablePadding: true,
        label: 'Order Type',
        sort: false,
        type: 'text',
    },
    // {
    //     id: 'date',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Date',
    //     sort: false,
    //     type: 'date',
    // },
    {
        id: 'totalOrders',
        numeric: false,
        disablePadding: false,
        label: 'Qty of Orders',
        sort: false,
        type: 'number',
    },
    {
        id: 'totalSales',
        numeric: true,
        disablePadding: false,
        label: 'Total Sales',
        sort: true,
        type: 'number',
    },
    {
        id: 'average',
        numeric: true,
        disablePadding: false,
        label: 'Avg/Order',
        sort: true,
        type: 'number',
    },
];
export const HeadCellsSaleByMode = [
    {
        id: 'mode',
        numeric: false,
        disablePadding: true,
        label: 'Order Mode',
        sort: false,
        type: 'text',
    },
    // {
    //     id: 'date',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Date',
    //     sort: false,
    //     type: 'date',
    // },
    {
        id: 'totalOrders',
        numeric: false,
        disablePadding: false,
        label: 'Qty of Orders',
        sort: false,
        type: 'number',
    },
    {
        id: 'totalSales',
        numeric: true,
        disablePadding: false,
        label: 'Total Sales',
        sort: true,
        type: 'number',
    },
    {
        id: 'average',
        numeric: true,
        disablePadding: false,
        label: 'Avg/Order',
        sort: true,
        type: 'number',
    },
];

export const HeadCellsProduct = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: '#',
        sort: true,
        type: 'text',
    },
    {
        id: 'product_name',
        numeric: false,
        disablePadding: false,
        label: 'Product',
        sort: false,
        type: 'text',
    },
    {
        id: 'qyt',
        numeric: false,
        disablePadding: false,
        label: 'Qty of Sales',
        sort: false,
        type: 'number',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'Sales Total',
        sort: true,
        type: 'number',
    },
];
export const HeadCellsGiftCard = [
    // {
    //     id: 'id',
    //     numeric: true,
    //     disablePadding: true,
    //     label: '#',
    //     sort: true,
    //     type: 'text',
    // },
    {
        id: 'location',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        sort: false,
        type: 'date',
    },
    {
        id: 'totalOrders',
        numeric: false,
        disablePadding: false,
        label: 'Total Order',
        sort: false,
        type: 'number',
    },
    {
        id: 'totalSales',
        numeric: true,
        disablePadding: false,
        label: 'Total Amount',
        sort: true,
        type: 'number',
    },
];

export const HeadCellsDiscount = [
    // {
    //     id: 'coupon',
    //     numeric: true,
    //     disablePadding: true,
    //     label: 'Coupon Code',
    //     sort: true,
    //     type: 'text',
    // },
    // {
    //     id: 'date',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'date',
    //     sort: false,
    //     type: 'date',
    // },
    {
        id: 'location',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        sort: false,
        type: 'date',
    },
    {
        id: 'totalOrders',
        numeric: false,
        disablePadding: false,
        label: 'Total Order',
        sort: false,
        type: 'number',
    },
    {
        id: 'totalSales',
        numeric: true,
        disablePadding: false,
        label: 'Total Amount',
        sort: true,
        type: 'number',
    },
];

export const HeadCellsDelivery = [
    // {
    //     id: 'id',
    //     numeric: true,
    //     disablePadding: true,
    //     label: '#',
    //     sort: true,
    //     type: 'text',
    // },
    // {
    //     id: 'location_name',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Location',
    //     sort: false,
    //     type: 'text',
    // },
    {
        id: 'location',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        sort: false,
        type: 'date',
    },
    {
        id: 'totalOrders',
        numeric: false,
        disablePadding: false,
        label: 'Total Order',
        sort: false,
        type: 'number',
    },
    {
        id: 'totalSales',
        numeric: true,
        disablePadding: false,
        label: 'Total Expenses Amount',
        sort: true,
        type: 'number',
    },

    // {
    //     id: 'date',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Date',
    //     sort: false,
    //     type: 'number',
    // },
    // {
    //     id: 'deliveries',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Qty of Deliveries',
    //     sort: false,
    //     type: 'number',
    // },
    // {
    //     id: 'totalDeliveryAmount',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Total Expenses Amount',
    //     sort: false,
    //     type: 'number',
    // },
];
export const HeadCellsRedemption = [
    // {
    //     id: 'id',
    //     numeric: true,
    //     disablePadding: true,
    //     label: '#',
    //     sort: true,
    //     type: 'text',
    // },
    // {
    //     id: 'location_name',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Location',
    //     sort: false,
    //     type: 'text',
    // },
    {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'Date',
        sort: false,
        type: 'date',
    },
    {
        id: 'total_order',
        numeric: true,
        disablePadding: false,
        label: 'Total Order',
        sort: false,
        type: 'total_order',
    },
    {
        id: 'total_amount',
        numeric: true,
        disablePadding: false,
        label: 'Total Expenses Amount',
        sort: false,
        type: 'number',
    },
];
export const HeadCellsEmployee = [
    // {
    //     id: 'id',
    //     numeric: true,
    //     disablePadding: true,
    //     label: '#',
    //     sort: true,
    //     type: 'text',
    // },
    // {
    //     id: 'location_name',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Location',
    //     sort: false,
    //     type: 'text',
    // },
    {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'Date',
        sort: false,
        type: 'date',
    },
    {
        id: 'total_order',
        numeric: true,
        disablePadding: false,
        label: 'Total Order',
        sort: false,
        type: 'total_order',
    },
    {
        id: 'total_amount',
        numeric: true,
        disablePadding: false,
        label: 'Total Expenses Amount',
        sort: false,
        type: 'number',
    },
];

export const dataFieldsTax = ['product_Names', 'totalOrders', 'totalSales'];

export const dataFieldsSale = ['location', 'totalOrders', 'totalSales', 'average'];
export const dataFieldsSaleByType = ['type', 'totalOrders', 'totalSales', 'average'];
export const dataFieldsSaleByMode = ['mode', 'totalOrders', 'totalSales', 'average'];

export const dataFieldsProduct = ['id', 'product_name', 'qyt', 'price'];

export const dataFieldsDiscount = ['location', 'totalOrders', 'totalSales'];
export const dataFieldsGiftCard = ['location', 'totalOrders', 'totalSales'];

export const dataFieldsDelivery = ['location', 'totalOrders', 'totalSales'];
export const dataFieldsRedemption = ['date', 'total_order', 'total_amount'];
export const dataFieldsEmployee = ['date', 'total_order', 'total_amount'];
